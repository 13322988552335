import * as React from "react"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography, TypographyVariant } from "@material-ui/core";
import { graphql, StaticQuery } from "gatsby";
import CheckIcon from '@material-ui/icons/Check';

interface Props {
    text: string;
    typography?: TypographyVariant;
    uncircled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center'
        },
        iconWrapper: {
            display: 'flex',
            marginRight: '10px',
            minWidth: '25px',
        },
    }),
);

const Checkmark: React.FC<Props> = ({ text, typography, uncircled }) => {
    const classes = useStyles();

    return <StaticQuery
        query={
            graphql`
                query CheckmarkQuery {
                    strapiUtils {
                        checkmark {
                            localFile {
                                publicURL
                            }
                        }
                    }
                }`
        }
        render={data => {
            return <div className={classes.root}>
                <div className={classes.iconWrapper}>
                    {uncircled
                        ? <CheckIcon color="primary" />
                        : <img src={data.strapiUtils.checkmark.localFile.publicURL} alt="✓" />}
                </div>

                <div>{typography ? <Typography variant={typography}>{text}</Typography> : text}</div>
            </div>
        }} />
}

export default Checkmark
