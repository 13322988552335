import * as React from "react"
import { graphql, PageProps } from "gatsby"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import { Container, Grid, Typography } from "@material-ui/core"
import Row from "../components/Row";
import Quotation from "../components/Quotation";
import BlogPosts from "../components/BlogPosts";
import Headline from "../components/Headline";
import Checkmark from "../components/Checkmark";
import Section from "../components/Section";
import CardBasic from "../components/CardBasic";
import Card from "../components/Card";
import theme from "../gatsby-theme-material-ui-top-layout/theme";
import { FooterProps } from "../components/Footer";
import { NavigationProps } from "../components/Header/navigation";
import markdownToTxt from 'markdown-to-txt';
import { useContext } from "react";
import { LocaleContext } from "../context/LocaleContext";
import ReactMarkdown from "react-markdown";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gradientBG: {
      backgroundImage: 'linear-gradient(13deg, #f5f9f9 19%, #fff 79%, #fff 82%, #fff 82%)',
    },
    banner: {
      maxWidth: 627,
      padding: '120px 0',
      margin: '0 auto',
    },
    topImageSection: {
      padding: '0 0 120px 0',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      [theme.breakpoints.down('md')]: {
        padding: '0 0 25px 0',
      },
    },
    checkmark: {
      margin: '0 25px'
    },
    checkmarks: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '50px',
      flexDirection: 'column',

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    imageWrapper: {
      textAlign: 'center',
    },
    listCards: {
      paddingBottom: '100px',
    },
    topHeadline: {
      padding: '50px 40px'
    },
  }),
);

type DataProps = {
  strapiHomepage: any
  strapiNavigation: NavigationProps
  strapiFooter: FooterProps
}

const IndexPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const classes = useStyles();
  const localeContext = useContext(LocaleContext);

  const navigation = data.strapiNavigation;
  const footer = data.strapiFooter;
  const hp = data.strapiHomepage;
  const topHeadline = hp.topHeadline;
  const mainImage = hp.mainImage?.localFile.childImageSharp.gatsbyImageData;
  const checkmarks = hp.checkmarks;
  const basicCards = hp.BasicCards;
  const listCards = hp.Cards;
  const quotation = hp.Quote;
  const blogPosts = hp.BlogPosts;
  const bottomRow = hp.BottomRow;

  return (
    <Layout navigation={navigation} footer={footer}>
      <Seo
        title={
          `${topHeadline.titleBefore ? topHeadline.titleBefore : ''} ${topHeadline.highlighted ? topHeadline.highlighted : ''} ${topHeadline.titleAfter ? topHeadline.titleAfter : ''}`
        }
        description={<ReactMarkdown linkTarget="_blank" >{topHeadline.text}</ReactMarkdown>}
      />
      <div className={classes.gradientBG}>
        <Container maxWidth="lg">

          {topHeadline && <Headline
            titleBefore={topHeadline.titleBefore}
            highlighted={topHeadline.highlighted}
            titleAfter={topHeadline.titleAfter}
            text={topHeadline.text}
            inputLabel={topHeadline.inputLabel}
            inputButtonText={topHeadline.inputButtonText}
            mainLink={topHeadline.mainLink}
            badge={topHeadline.badge}
          />}

          <div className={classes.topImageSection}>
            <div className={classes.checkmarks}>
              {checkmarks.map(item => <div className={classes.checkmark}><Checkmark text={item.text} typography="h4" /></div>)}
            </div>

            <div className={classes.imageWrapper}>
              <GatsbyImage image={mainImage} alt="screen desktop" />
            </div>
          </div>


          <Typography variant="h2" align="center" className={classes.topHeadline}>
            {hp.RowsTitle}
          </Typography>

          {hp.Rows.map((row, i) => {
            const { id, title, description, buttonText, image, url } = row;
            const gatsbyImage = image && image.localFile.childImageSharp.gatsbyImageData;

            return <Row
              key={id}
              title={title}
              description={description}
              imageRight={i % 2 ? true : false}
              url={`/${localeContext.locale}/${url}`}
              image={gatsbyImage}
              buttonText={buttonText}
            />
          })}
        </Container>
      </div>


      {basicCards && <Section color="white">
        <Typography variant="h2" align="center">
          {hp.BasicCardsHeadline}
        </Typography>
        <Grid container spacing={4}>
          {basicCards.map(card => <Grid item xs={6} sm={4}>
            <CardBasic
              key={card.id}
              publicURL={card.icon && card.icon.localFile.publicURL}
              title={card.title}
              description={card.description}
            />
          </Grid>)}
        </Grid>
      </Section>}

      {listCards && <Section color={theme.palette.secondary.main}>
        <Typography variant="h2" align="center">
          {hp.CardsHeadline}
        </Typography>
        <Grid container spacing={4} className={classes.listCards}>
          {listCards.map(card => <Grid item xs={12} sm={6} md={4}>
            <Card
              key={card.id}
              publicURL={card.icon && card.icon.localFile.publicURL}
              title={card.title}
              listItems={card.ListItems}
              buttonText={card.buttonText}
              url={card.link}
              locale={hp.locale}
            />
          </Grid>
          )}

        </Grid>
      </Section>}

      {quotation && <Quotation
        text={quotation.text}
        highlighted={quotation.highlighted}
        profile={{
          ...quotation.profile,
          icon: quotation.profile.icon && quotation.profile.icon.localFile.publicURL
        }}
      />}

      {bottomRow && <div>
        <Container maxWidth="lg">
          <Row
            title={bottomRow.title}
            description={bottomRow.description}
            imageRight
            url={bottomRow.url}
            image={bottomRow.image && bottomRow.image.localFile.childImageSharp.gatsbyImageData}
            buttonPrimary
            buttonText={bottomRow.buttonText}
          />
        </Container>
      </div>}

      {blogPosts && <BlogPosts
        title={hp.BlogPostsHeadline}
        blogPosts={blogPosts.map(post => {
          return {
            ...post,
            image: post.image && post.image.localFile.childImageSharp.gatsbyImageData
          }
        })
        }
      />}
    </Layout >
  );
}

export default IndexPage

export const query = graphql`
  query HomepageQuery($locale: String) {
    strapiHomepage(locale: {eq: $locale}) {
      locale
      BasicCards {
        title
        description
        icon {
          localFile {
            publicURL
          }
        }
        id
      }
      BasicCardsHeadline
      BlogPosts {
        description
        id
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      BlogPostsHeadline
      BottomRow {
        badge
        buttonText
        description
        id
        title
        url
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Cards {
        ListItems {
          id
          text
        }
        buttonText
        icon {
          localFile {
            publicURL
          }
        }
        id
        title
        link
      }
      CardsHeadline
      Quote {
        text
        highlighted
        id
        profile {
          id
          name
          role
          company
          icon {
            localFile {
              publicURL
            }
          }
        }
      }
      Rows {
        badge
        buttonText
        description
        id
        title
        url
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      RowsTitle
      ViewMoreBlogPostsButton
      bottomHeadline {
        highlighted
        id
        inputButtonText
        inputLabel
        titleAfter
        titleBefore
      }
      checkmarks {
        id
        text
      }
      id
      mainImage {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      seo {
        id
        metaDescription
        metaTitle
      }
      topHeadline {
        badge
        dividedLinks {
          id
          link
          text
        }
        highlighted
        id
        text
        inputButtonText
        inputLabel
        titleBefore
        titleAfter
        rightImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        mainLink {
            link
            text
        }
      }
    }
    strapiNavigation(locale: {eq: $locale}) {
        locale
        navigationItem {
            title
            customLink
            NavigationSubItems {
                title
                sub_page {
                    slug
                    pageTitle
                }
                slug
            }
        }
        ContactUsButton {
            link
            text
        }
    }
    strapiFooter(locale: {eq: $locale}) {
      bottomLinks {
        id
        link
        text
      }
      locale
      columns {
          FooterColumnItem {
              text
              link
              anchor
          }
          Title
      }
      contactsSectionLinks {
          link
          text
      }
      city
      state
      zipCode
      email
      id
      identification
      mobilePhone
      street
      socialMedia {
          name
          image {
              localFile {
                  publicURL
              }
          }
          link
      }
      bottomText
      bottomHeadlineSection {
      mainLink {
        link
        text
      }
          inputButtonText
          highlighted
          inputLabel
          titleAfter
          titleBefore
      }
    }
  }
`;
