import * as React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Divider, Typography } from "@material-ui/core";
import Checkmark from "../Checkmark";
import CustomLink from "../CustomLink";

interface ListItem {
    id: string;
    text: string;
}
interface Props {
    gatsbyImage?: IGatsbyImageData;
    publicURL?: string;
    title: string;
    listItems: ListItem[];
    url?: string;
    buttonText: string;
    locale?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: 'white',
            padding: '30px',
            boxShadow: '0 22px 34px 0 rgba(194, 194, 194, 0.2)',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        verticalMargin: {
            margin: '30px 0',
        },
        listItems: {
            color: theme.palette.secondary.main,
        },
        link: {
            textDecoration: 'none',
        },
    }),
);

const Card: React.FC<Props> = ({ gatsbyImage, publicURL, title, listItems, buttonText, url, locale }) => {
    const classes = useStyles();

    return <div className={classes.root}>
        <div>
            <div>{publicURL ? <img src={publicURL} /> : <GatsbyImage image={gatsbyImage} alt="img" />}</div>
            <Typography variant="h3" gutterBottom className={classes.verticalMargin}>
                {title}
            </Typography>
            <div>
                {listItems.map(item =>
                    <Checkmark key={item.id} text={item.text} typography="body1" />
                )}
            </div>
        </div>

        <div>
            <Divider className={classes.verticalMargin} />
            <CustomLink link={url} locale={locale} className={classes.link}>
                <Button size="large">{buttonText}</Button>
            </CustomLink>
        </div>
    </div>
}

export default Card
